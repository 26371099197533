export default {
    // Endpoints
    loginEndpoint: '/admin/auth/login',
    moduleEndpoint: '/admin/auth/clientmodules',
    logincompanyEndpoint: '/admin/auth/logincompany',
    
    registerEndpoint: '/jwt/register',
    refreshEndpoint: '/jwt/refresh-token',
    logoutEndpoint: '/jwt/logout',
  
    getUsers: '/admin/auth/f/users',
    getUsersByRole: '/admin/auth/f/usersbyrole',
    addUser: '/admin/auth/createuser',
  
    getCategories: '/lms/admin/course/category',
    addCategory: '/lms/admin/course/category',
  
    tokenType: '',
  
    // Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageClientAuthTokenKeyName: 'clientAuthToken',
    storageClientLogoKeyName: 'clientLogo',
    
    storageRefreshTokenKeyName: 'refreshToken',
  }
  