import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import usejwt from '@/auth/jwt/useJwt'
import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'

import '@axios'
// import '@/libs/acl'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// JWT authentication
Vue.use(usejwt)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueCompositionAPI)

// Composition API

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

console.log(process.env.VUE_APP_API_URL)

require('dotenv').config()

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  //   ready() {
  //   LocationPickerInit(
  //     this,

  //     // config object
  //     {
  //       key: 'AIzaSyCkVyfcqbbrrYcdQqsaJv2meHyiUV0Sjes', // required
  //       language: 'en-US'        // optional
  //     },

//     // options object
//     {
//       map: {
//         center: { lat: 0, lng: 0 },
//         /** other map options **/
//       },
//       marker: { /** marker options **/ },
//       infoWindow: { /** info window options **/ },
//       autocomplete: { /** autocomplete options **/ }
//     }
//   );
// }
}).$mount('#app')

// 4a8cff  acik mavi
// 003ba3 koyu mavi
